import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid, Typography } from '@mui/material';
import { FullHeightContainer } from 'components/container';
import { FormAlert } from 'components/alert';
import { useState } from 'react';
import { FormModal } from 'components/modal';
import { showSnackbar } from 'utils';
import { DatePicker, Select, TextInput } from 'components/common/input';
import { DateTime } from 'luxon';
import { MailOutline, SendOutlined, SmsOutlined } from '@mui/icons-material';
import { api } from 'api';
import { ParticipantWithLastHearingTestResultViewItem } from 'api/services/auth/types';

interface ParticipantFormProps {
  participant: ParticipantWithLastHearingTestResultViewItem | null;
  locationId: number;
  title: string;
  handleClose: () => void;
  handleSubmit: () => void;
}

const ParticipantFormSchema = Yup.object().shape({
  from: Yup.object()
    .required('Required')
    .test('isBefore', 'Should be before To Date', (value, testContext) => {
      const { to } = testContext.parent;
      try {
        return (
          (value as DateTime).startOf('day') <= (to as DateTime).startOf('day')
        );
      } catch {
        return true;
      }
    }),
  to: Yup.object()
    .required('Required')
    .test('isAfter', 'Should be after From Date', (value, testContext) => {
      const { from } = testContext.parent;
      try {
        return (
          (value as DateTime).startOf('day') >=
          (from as DateTime).startOf('day')
        );
      } catch {
        return true;
      }
    }),
});

export function SendReportForm(props: ParticipantFormProps) {
  const { locationId, title, participant, handleSubmit, handleClose } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const initialValues = {
    from: DateTime.now().minus({ month: 3 }),
    to: DateTime.now(),
    type: 'email',
  };

  const onSubmit = async (values: typeof initialValues) => {
    try {
      const body = {
        from: values.from.toFormat('yyyy-MM-dd'),
        to: values.to.toFormat('yyyy-MM-dd'),
        sendEmail: values.type === 'email',
        sendSms: values.type === 'sms',
      };
      setLoading(true);
      if (participant) {
        await api.participant.sendHearingTestResultsReport(
          participant.id,
          body,
        );
      } else {
        await api.organization.sendParticipantReports(locationId, body);
      }
      handleSubmit();
      showSnackbar(`Report successfully sent`, {
        variant: 'success',
      });
    } catch (e: any) {
      setError(
        e?.response?.data?.error || e?.message || 'Could not send report',
      );
    } finally {
      setLoading(false);
    }
  };

  const typeOptions = [
    {
      label: (
        <Grid container alignItems="center" gap={1}>
          <MailOutline fontSize="small" />
          <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
            Send by email
          </Typography>
        </Grid>
      ),
      value: 'email',
    },
    {
      label: (
        <Grid container alignItems="center" gap={1}>
          <SmsOutlined fontSize="small" />
          <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
            Send by text message
          </Typography>
        </Grid>
      ),
      value: 'sms',
    },
  ];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ParticipantFormSchema}
      enableReinitialize
      validateOnChange
    >
      {({ values, touched, errors, setFieldValue, handleSubmit }) => (
        <FormModal
          open
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title={title}
          submitButtonTitle="Send"
          submitButtonIcon={<SendOutlined sx={{ rotate: '-45deg' }} />}
          isSubmitting={loading}
          PaperProps={{ sx: { maxWidth: '520px' } }}
        >
          <FullHeightContainer maxWidth="sm" disableGutters>
            <Typography pb={2} sx={{ fontSize: '18px' }}>
              Date
            </Typography>
            <Grid
              component="form"
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit();
                }
              }}
              container
              flexWrap="nowrap"
              flexDirection={{ xs: 'column', sm: 'row' }}
              gap={{ xs: 0, sm: 2 }}
            >
              <Grid item xs={12} sm={6}>
                <Grid item xs={12}>
                  <DatePicker
                    slots={{ textField: TextInput }}
                    slotProps={{ textField: { variant: 'standard' } }}
                    value={values.from}
                    onChange={(newDateFrom: DateTime | null) => {
                      setFieldValue('from', newDateFrom);
                    }}
                    disableFuture
                    errorMessage={
                      touched.from && errors.from && (errors.from as string)
                    }
                  />
                </Grid>
              </Grid>

              <Typography pb={2} sx={{ fontSize: '18px' }}>
                to
              </Typography>

              <Grid item xs={12} sm={6}>
                <Grid item xs={12}>
                  <DatePicker
                    slots={{ textField: TextInput }}
                    slotProps={{ textField: { variant: 'standard' } }}
                    value={values.to}
                    onChange={(newDateTo: DateTime | null) => {
                      setFieldValue('to', newDateTo);
                    }}
                    disableFuture
                    errorMessage={
                      touched.to && errors.to && (errors.to as string)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container sx={{ maxWidth: { xs: '100%', sm: '216px' } }}>
              <Select
                variant="standard"
                options={typeOptions}
                value={values.type}
                displayEmpty
                onChange={(e) => setFieldValue('type', e.target.value)}
                errorMessage={
                  touched?.type && errors?.type ? (errors?.type as string) : ''
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  {error ? (
                    <FormAlert severity="error">{error}</FormAlert>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </FullHeightContainer>
        </FormModal>
      )}
    </Formik>
  );
}
