import { PaginatedData } from 'common/types';
import FileDownload from 'js-file-download';
import { api } from 'api';
import { PaginatedRequestParams, RequestParams } from 'api/types';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import _ from 'lodash';
import axios from '../../axios';
import {
  Organization,
  OrganizationBody,
  LocationBody,
  ILocation,
  Shift,
  AssignHCPSupervisorBody,
  ILocationParticipantsHearingTestsSummary,
  IVisit,
  VisitBody,
  VisitTimeBody,
  IVisitTime,
  VisitShift,
  ILocationParticipantsHearingMetric,
  IReferralListItem,
  ISignedDocument,
  IDailyBiologicalReport,
  SendParticipantReportsBody,
  IHCPSupervisorLocation,
  IEquipmentSpecificationsAndNoiseLevelsReport,
  ICreateAudiologistCoverLetterBody,
  ICoverLetterAndSummaryOfTesting,
  EReportTypeKeys,
  ICombinedReport,
  GetLocationReportTypes,
  IAvailableTimeSlot,
} from './types';
import {
  ClinicReferralParticipants,
  ParticipantWithLastHearingTestResultViewItem,
  TimeSlot,
  UnscheduledParticipant,
  User,
} from '../auth/types';
import { ExternalClinic } from '../external-clinic/types';

export class OrganizationAPI {
  async getOrganizations({
    limit = 10,
    offset = 0,
    search,
  }: PaginatedRequestParams) {
    const { data } = await axios.get(`/organization`, {
      params: {
        limit,
        offset,
        search,
      },
    });

    return data as PaginatedData<Organization>;
  }

  async getOrganizationById(id: number | string) {
    const { data } = await axios.get<Organization>(`/organization/${id}`);

    return data;
  }

  async createOrganization(body: OrganizationBody) {
    const { data } = await axios.post('/organization', body);

    return data as Organization;
  }

  async updateOrganization(id: number, body: OrganizationBody) {
    const { data } = await axios.put(`/organization/${id}`, body);

    return data as Organization;
  }

  async getLocations(
    organizationId: number | string,
    { limit = 10, offset = 0, search }: PaginatedRequestParams,
  ) {
    const { data } = await axios.get(
      `/organization/${organizationId}/location`,
      {
        params: {
          limit,
          offset,
          search,
        },
      },
    );

    return data as PaginatedData<ILocation>;
  }

  async getAllLocations({
    limit = 10,
    offset = 0,
    search,
  }: PaginatedRequestParams) {
    const { data } = await axios.get('/organization/locations/all', {
      params: {
        limit,
        offset,
        search,
      },
    });

    return data as PaginatedData<ILocation>;
  }

  async getSiteManagers(organizationId: number | string) {
    const { data } = await axios.get(
      `/organization/${organizationId}/site-manager`,
    );

    return data as PaginatedData<User>;
  }

  async getHCPSupervisorLocationsList(
    organizationId: number | string,
    { limit = 10, offset = 0, search }: PaginatedRequestParams,
  ) {
    const { data } = await axios.get(
      `/organization/${organizationId}/hcp-supervisor/location`,
      {
        params: {
          limit,
          offset,
          search,
        },
      },
    );

    return data as PaginatedData<IHCPSupervisorLocation>;
  }

  async getLocationById(id: number | string) {
    const { data } = await axios.get(`/organization/location/${id}`);

    return data as ILocation;
  }

  async createLocation(organizationId: number | string, body: LocationBody) {
    const { data } = await axios.post(
      `/organization/${organizationId}/location`,
      body,
    );

    return data as ILocation;
  }

  async updateLocation(id: number, body: LocationBody) {
    const { data } = await axios.put(`/organization/location/${id}`, body);

    return data as ILocation;
  }

  async getLocationShifts(
    locationId: number | string,
    { limit = 10, offset = 0, search }: PaginatedRequestParams,
  ) {
    const { data } = await axios.get(
      `/organization/location/${locationId}/shifts`,
      {
        params: {
          limit,
          offset,
          search,
        },
      },
    );

    return data as PaginatedData<Shift>;
  }

  async createLocationShift(locationId: number | string, body: any) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/shifts`,
      body,
    );

    return data as Shift;
  }

  async updateLocationShift(
    locationId: number | string,
    shiftId: number | string,
    body: any,
  ) {
    const { data } = await axios.put(
      `/organization/location/${locationId}/shifts/${shiftId}`,
      body,
    );

    return data as Shift;
  }

  async deleteLocationShift(
    locationId: number | string,
    shiftId: number | string,
  ) {
    const { data } = await axios.delete(
      `/organization/location/${locationId}/shifts/${shiftId}`,
    );

    return data as Shift;
  }

  async deleteSiteManager(id: number | string, siteManagerId: number | string) {
    const { data } = await axios.delete(
      `/organization/${id}/site-manager/${siteManagerId}`,
    );

    return data as { success: true };
  }

  async getHCPSupervisors(
    organizationId: number | string,
    { limit = 10, offset = 0 }: PaginatedRequestParams,
  ) {
    const { data } = await axios.get(
      `/organization/${organizationId}/hcp-supervisor`,
      {
        params: {
          limit,
          offset,
        },
      },
    );

    return data as PaginatedData<User>;
  }

  async getAvailableHCPSupervisors(
    organizationId: number | string,
    { limit = 10, offset = 0, search }: PaginatedRequestParams,
  ) {
    const { data } = await axios.get(
      `/organization/${organizationId}/hcp-supervisor/available`,
      {
        params: {
          limit,
          offset,
          search,
        },
      },
    );

    return data as PaginatedData<User>;
  }

  async assignHCPSupervisorToOrganization(
    id: number | string,
    action: 'assign' | 'unassign',
    body: AssignHCPSupervisorBody,
  ) {
    const { data } = await axios.post(
      `/organization/${id}/hcp-supervisor/${action}`,
      body,
    );

    return data as User;
  }

  async getParticipantsByLocationId(
    id: number | string,
    {
      limit = 10,
      offset = 0,
      orderBy,
      orderDirection,
      search,
      filterParams,
    }: PaginatedRequestParams,
  ) {
    const { data } = await axios.get(
      `organization/location/${id}/participants`,
      {
        params: {
          limit,
          offset,
          orderBy,
          orderDirection,
          search,
          ...filterParams,
        },
      },
    );
    return data as PaginatedData<ParticipantWithLastHearingTestResultViewItem>;
  }

  async getExternalClinicsByLocationId(
    id: number | string,
    {
      limit = 10,
      offset = 0,
      orderBy,
      orderDirection,
      search,
      filterParams,
    }: PaginatedRequestParams,
  ) {
    const { data } = await axios.get(
      `organization/location/${id}/external-clinics`,
      {
        params: {
          limit,
          offset,
          orderBy,
          orderDirection,
          search,
          ...filterParams,
        },
      },
    );
    return data as PaginatedData<ExternalClinic>;
  }

  async createClinicReferral(
    locationId: number | string,
    participantId: number | string,
    externalClinicId: number | string,
    hearingTestResultId?: number | string,
  ) {
    const { data } = await axios.post(
      `organization/location/${locationId}/clinic-referral`,
      {
        participantId,
        externalClinicId,
        hearingTestResultId,
      },
    );
    return data as ClinicReferralParticipants;
  }

  async updateClinicReferral(
    locationId: number | string,
    clinicReferralId: number | string,
    participantId: number | string,
    externalClinicId: number | string,
    hearingTestResultId?: number | string,
  ) {
    const { data } = await axios.put(
      `organization/location/${locationId}/clinic-referral/${clinicReferralId}`,
      {
        participantId,
        externalClinicId,
        hearingTestResultId,
      },
    );
    return data as ClinicReferralParticipants;
  }

  async getLocationParticipantsHearingTestsSummary(
    locationId: number | string,
    params?: { showInactive?: boolean },
  ) {
    const { data } = await axios.get(
      `organization/location/${locationId}/participants/hearing-tests-summary`,
      { params },
    );
    return data as ILocationParticipantsHearingTestsSummary;
  }

  async getOrganizationParticipantsHearingTestsSummary(
    organizationId: number | string,
    params?: RequestParams,
  ) {
    const { data } = await axios.get(
      `organization/${organizationId}/hearing-tests-summary`,
      { params },
    );
    return data as ILocationParticipantsHearingTestsSummary;
  }

  async getLocationParticipantsHearingMetricsByLocationId(
    locationId: number | string,
    dateFrom: string,
    dateTo: string,
  ) {
    const { data } = await axios.get<ILocationParticipantsHearingMetric>(
      `organization/location/${locationId}/participants/metrics`,
      {
        params: {
          dateFrom,
          dateTo,
        },
      },
    );
    return data;
  }

  async getLocationParticipantsHearingMetricsByLocationIdsList(
    locationIds: number[],
    dateFrom: string,
    dateTo: string,
  ) {
    const { data } = await axios.get<ILocationParticipantsHearingMetric>(
      `organization/location/participants/metrics`,
      {
        params: {
          dateFrom,
          dateTo,
          locationIds,
        },
      },
    );
    return data;
  }

  async exportEMRRResults(id: number | string) {
    const { data } = await axios.post(
      `organization/${id}/hearing-test-results/export-emr`,
    );

    return FileDownload(data, 'emr-results.csv');
  }

  async exportLocationParticipantsData(id: number | string) {
    const { data } = await axios.post(
      `organization/location/${id}/participants/export`,
    );

    return FileDownload(data, 'participants.csv');
  }

  async getLocationDepartments(
    locationId: number | string,
    { limit = 10, offset = 0, search }: PaginatedRequestParams,
  ) {
    const { data } = await axios.get(
      `/organization/location/${locationId}/participants/departments`,
      {
        params: {
          limit,
          offset,
          search,
        },
      },
    );

    return data as PaginatedData<string[]>;
  }

  async getVisitById(locationId: number | string, visitId: number | string) {
    const { data } = await axios.get(
      `/organization/location/${locationId}/visits/${visitId}`,
    );

    return data as IVisit;
  }

  async getLocationVisits(
    locationId: number | string,
    {
      limit = 10,
      offset = 0,
      search,
      shared,
    }: PaginatedRequestParams & { shared?: boolean },
  ) {
    const { data } = await axios.get(
      `/organization/location/${locationId}/visits`,
      {
        params: {
          limit,
          offset,
          search,
          shared,
        },
      },
    );

    return data as PaginatedData<IVisit>;
  }

  async createLocationVisit(locationId: number | string, body: VisitBody) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/visits`,
      body,
    );

    return data as IVisit;
  }

  async updateLocationVisit(
    locationId: number | string,
    visitId: number | string,
    body: Partial<VisitBody>,
  ) {
    const { data } = await axios.put(
      `/organization/location/${locationId}/visits/${visitId}`,
      body,
    );

    return data as IVisit;
  }

  async createVisitTime(
    locationId: number | string,
    visitId: number | string,
    body: VisitTimeBody,
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/visits/${visitId}/visit-times`,
      body,
    );

    return data as IVisitTime;
  }

  async updateVisitTime(
    locationId: number | string,
    visitId: number | string,
    visitTimeId: number | string,
    body: VisitTimeBody,
  ) {
    const { data } = await axios.put(
      `/organization/location/${locationId}/visits/${visitId}/visit-times/${visitTimeId}`,
      body,
    );

    return data as IVisitTime;
  }

  async deleteVisitTime(
    locationId: number | string,
    visitId: number | string,
    visitTimeId: number | string,
  ) {
    const { data } = await axios.delete(
      `/organization/location/${locationId}/visits/${visitId}/visit-times/${visitTimeId}`,
    );

    return data as IVisitTime;
  }

  async generateSchedule(
    locationId: number | string,
    visitId: number | string,
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/visits/${visitId}/generate-schedule`,
    );

    return data as IVisit;
  }

  async getVisitShifts(
    locationId: number | string,
    visitId: number | string,
    { limit = 10, offset = 0, search }: PaginatedRequestParams,
  ) {
    const { data } = await axios.get(
      `/organization/location/${locationId}/visits/${visitId}/shifts`,
      {
        params: {
          limit,
          offset,
          search,
        },
      },
    );

    return data as PaginatedData<VisitShift>;
  }

  async getAvailableVisitShifts(
    locationId: number | string,
    visitId: number | string,
    body: { startTime: string },
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/visits/${visitId}/available-shifts`,
      body,
    );

    return data as PaginatedData<VisitShift>;
  }

  async getUnscheduledParticipants(
    locationId: number | string,
    visitId: number | string,
    { limit = 10, offset = 0, search }: PaginatedRequestParams,
  ) {
    const { data } = await axios.get(
      `/organization/location/${locationId}/visits/${visitId}/unscheduled-participants`,
      {
        params: {
          limit,
          offset,
          search,
        },
      },
    );

    return data as PaginatedData<UnscheduledParticipant>;
  }

  async setParticipantNotDeferred(
    locationId: number | string,
    visitId: number | string,
    participantId: number | string,
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/visits/${visitId}/participants/${participantId}/set-not-deferred`,
    );

    return data as UnscheduledParticipant;
  }

  async setParticipantDeferred(params: {
    locationId: number | string;
    visitId: number | string;
    participantId: number | string;
    deferringReason: string;
    deferringReasonNote?: string | null;
  }) {
    const {
      locationId,
      visitId,
      participantId,
      deferringReason,
      deferringReasonNote,
    } = params;
    const { data } = await axios.post(
      `/organization/location/${locationId}/visits/${visitId}/participants/${participantId}/set-deferred`,
      {
        deferringReason,
        deferringReasonNote,
      },
    );

    return data as UnscheduledParticipant;
  }

  async unscheduleParticipant(
    locationId: number | string,
    visitId: number | string,
    participantId: number | string,
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/visits/${visitId}/participants/${participantId}/unschedule`,
    );

    return data as UnscheduledParticipant;
  }

  async recalculateHearingTestParametersInOrganization(
    organizationId: number | string,
  ) {
    const { data } = await axios.post(
      `/organization/${organizationId}/hearing-test-results/recalculate`,
    );

    return data as {
      success: true;
      totalResultsCount: number;
      successfullyRecalculatedResultsCount: number;
    };
  }

  async recalculateHearingTestParametersInLocation(
    locationId: number | string,
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/hearing-test-results/recalculate`,
    );

    return data as {
      success: true;
      totalResultsCount: number;
      successfullyRecalculatedResultsCount: number;
    };
  }

  async recalculateHearingTestParameters(resultId: number | string) {
    const { data } = await axios.post(
      `/organization/hearing-test-results/${resultId}/recalculate`,
    );

    return data as {
      success: boolean;
    };
  }

  async deleteHearingTest(resultId: number | string) {
    const { data } = await axios.delete(
      `/organization/hearing-test-results/${resultId}/`,
    );

    return data as {
      success: boolean;
    };
  }

  async getVisitTimeSlots(
    locationId: number | string,
    visitId: number | string,
    visitTimeId: number | string,
  ) {
    const { data } = await axios.get(
      `/organization/location/${locationId}/visits/${visitId}/visit-times/${visitTimeId}/time-slots`,
    );

    return data as TimeSlot[];
  }

  async getAvailableVisitTimeSlots(
    locationId: number | string,
    participantId: number | string,
    visitId: number | string,
  ) {
    const { data } = await axios.get(
      `/organization/location/${locationId}/participants/${participantId}/visits/${visitId}/available-time-slots`,
    );

    return data as IAvailableTimeSlot[];
  }

  async bookTimeSlotForVisitTime(
    locationId: number | string,
    visitId: number | string,
    visitTimeId: number | string,
    visitTimeShiftId: number | string,
    participantId: number | string,
    body: { startTime: string },
  ) {
    await axios.post(
      `/organization/location/${locationId}/participants/${participantId}/visits/${visitId}/visit-times/${visitTimeId}/visit-times-shifts/${visitTimeShiftId}/book`,
      body,
    );
  }

  async bookParticipant(
    locationId: number | string,
    visitId: number | string,
    visitTimeId: number | string,
    visitTimeShiftId: number | string,
    participantId: number | string,
    body: { startTime: string },
  ) {
    await axios.post(
      `/organization/location/${locationId}/visits/${visitId}/visit-times/${visitTimeId}/visit-times-shifts/${visitTimeShiftId}/participants/${participantId}/book`,
      body,
    );
  }

  async generateVisitTimeToken(
    locationId: number | string,
    visitId: number | string,
    visitTimeId: number | string,
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/visits/${visitId}/visit-times/${visitTimeId}/generate-token`,
    );

    return data as { token: string };
  }

  async unassignProfessionalSupervisorFromLocation(
    locationId: number | string,
    userId: number | string,
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/unassign-professional-supervisor`,
      { userId },
    );

    return data as { success: true };
  }

  async assignProfessionalSupervisorToLocation(
    locationId: number | string,
    professionalSupervisorId: number | string,
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/assign-professional-supervisor`,
      { userId: professionalSupervisorId },
    );

    return data as { success: true };
  }

  async assignAccountManagerToOrganization(
    organizationId: number | string,
    accountManagerId: number | string,
    actionType: 'assign' | 'unassign',
  ) {
    const { data } = await axios.post(
      `/organization/${organizationId}/account-manager/${actionType}`,
      { accountManagerUserId: accountManagerId },
    );

    return data as { success: true };
  }

  async sendQuestionnaireInvitations(
    locationId: number | string,
    visitId: number | string,
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/visits/${visitId}/send-questionnaire-invitations`,
    );

    return data as { success: true };
  }

  async sendIndividualQuestionnaireInvitation(
    locationId: number | string,
    visitId: number | string,
    participantId: number | string,
    invitationType:
      | 'phone-questionnaire-invitation'
      | 'email-questionnaire-invitation'
      | 'questionnaire-invitation',
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/visits/${visitId}/participants/${participantId}/${invitationType}`,
    );

    return data as { success: true };
  }

  async sendBookingInvitations(
    locationId: number | string,
    visitId: number | string,
    body: { sendSms: boolean; sendEmail: boolean },
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/visits/${visitId}/send-booking-invitations`,
      body,
    );

    return data as { success: true };
  }

  async generateSchedulePDF(
    locationId: number | string,
    visitId: number | string,
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/visits/${visitId}/generate-schedule-pdf`,
    );

    return data as Partial<Omit<IVisit, 'visitTimes' | 'location'>>;
  }

  async getSchedulePDF(locationId: number | string, visitId: number | string) {
    const { data } = await axios.get(
      `/organization/location/${locationId}/visits/${visitId}/schedule-pdf`,
      {
        responseType: 'blob',
      },
    );

    return data as any;
  }

  async getReferralListsForLocation(
    locationId: number | string,
    params: PaginatedRequestParams,
  ) {
    const { data } = await axios.get(
      `/organization/location/${locationId}/referral-lists`,
      {
        params,
      },
    );

    return data as PaginatedData<IReferralListItem>;
  }

  async getReferralListPreviewParams(
    locationId: number | string,
    params: { dateFrom: string; dateTo: string },
  ) {
    const { data } = await axios.get(
      `/organization/location/${locationId}/referral-lists/preview-params`,
      { params },
    );

    return data as any;
  }

  async getOSHARecordableShiftReportsPreviewParams(
    locationId: number | string,
    params: { dateFrom: string; dateTo: string },
  ) {
    const { data } = await axios.get(
      `/organization/location/${locationId}/${EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT}/preview-params`,
      { params },
    );

    return data as any;
  }

  async getSTSReportsPreviewParams(
    locationId: number | string,
    params: { dateFrom: string; dateTo: string },
  ) {
    const { data } = await axios.get(
      `/organization/location/${locationId}/${EReportTypeKeys.STS_REPORT}/preview-params`,
      { params },
    );

    return data as any;
  }

  async signReferralList(
    locationId: number | string,
    body: {
      dateFrom: string;
      dateTo: string;
      signature: string;
      documentViewingDateTime: string;
      name: string;
    },
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/referral-lists/sign`,
      body,
    );

    return data as ISignedDocument;
  }

  async signOSHARecordableSTS(
    locationId: number | string,
    body: {
      dateFrom: string;
      dateTo: string;
      signature: string;
      documentViewingDateTime: string;
      name: string;
    },
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/${EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT}/sign`,
      body,
    );

    return data as ISignedDocument;
  }

  async signSTSReport(
    locationId: number | string,
    body: {
      dateFrom: string;
      dateTo: string;
      signature: string;
      documentViewingDateTime: string;
      name: string;
    },
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/${EReportTypeKeys.STS_REPORT}/sign`,
      body,
    );

    return data as ISignedDocument;
  }

  async downloadSignedReferralList(
    locationId: number | string,
    referralListId: number | string,
  ) {
    const { data } = await axios.get(
      `/organization/location/${locationId}/referral-lists/${referralListId}/download`,
      {
        responseType: 'blob',
      },
    );

    return data as any;
  }

  async getDailyBiologicalReportsForLocation(
    locationId: number | string,
    params: PaginatedRequestParams & { baseline?: boolean },
  ) {
    const { data } = await axios.get(
      `/organization/location/${locationId}/daily-biological-reports`,
      {
        params,
      },
    );

    return data as PaginatedData<IDailyBiologicalReport>;
  }

  async getDailyBiologicalReportById(reportId: number | string) {
    const { data } = await axios.get(
      `/organization/location/daily-biological-reports/${reportId}`,
    );

    return data as IDailyBiologicalReport;
  }

  async createDailyBiologicalReport(
    locationId: number,
    body: Omit<
      IDailyBiologicalReport,
      'id' | 'created_at' | 'updated_at' | 'signedDocument'
    >,
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/daily-biological-reports/`,
      body,
    );

    return data as IDailyBiologicalReport;
  }

  async updateDailyBiologicalReport(
    reportId: number,
    body: Omit<
      IDailyBiologicalReport,
      'id' | 'created_at' | 'updated_at' | 'signedDocument'
    >,
  ) {
    const { data } = await axios.put(
      `/organization/location/daily-biological-reports/${reportId}`,
      body,
    );

    return data as IDailyBiologicalReport;
  }

  async signDailyBiologicalReport(
    reportId: number,
    body: { signature: string; documentViewingDateTime: string },
  ) {
    const { data } = await axios.post(
      `/organization/location/daily-biological-reports/${reportId}/sign`,
      body,
    );

    return data as ISignedDocument;
  }

  async downloadSignedDailyBiologicalReport(referralListId: number | string) {
    const { data } = await axios.get(
      `/organization/location/daily-biological-reports/${referralListId}/download`,
      {
        responseType: 'blob',
      },
    );

    return data as any;
  }

  async sendParticipantReports(
    locationId: number,
    body: SendParticipantReportsBody,
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/send-participant-reports`,
      body,
    );

    return data as { success: true };
  }

  async getLocationReportsByType(
    locationId: number | string,
    reportType:
      | EReportTypeKeys.DAILY_BIOLOGICAL_REPORTS
      | EReportTypeKeys.MEDICAL_REFERRAL_LISTS
      | EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT
      | EReportTypeKeys.STS_REPORT
      | EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS
      | EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING
      | EReportTypeKeys.OTHER
      | EReportTypeKeys.ALL,
    {
      limit = 10,
      offset = 0,
      orderBy,
      orderDirection,
      search,
      filterParams,
    }: PaginatedRequestParams,
  ) {
    const reportTypes =
      reportType === EReportTypeKeys.ALL
        ? [
            EReportTypeKeys.DAILY_BIOLOGICAL_REPORTS,
            EReportTypeKeys.MEDICAL_REFERRAL_LISTS,
            EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT,
            EReportTypeKeys.STS_REPORT,
            EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS,
            EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING,
            EReportTypeKeys.OTHER,
          ]
        : [reportType];
    const { data } = await axios.get<PaginatedData<ICombinedReport>>(
      `/organization/location/${locationId}/reports`,
      {
        params: {
          limit,
          offset,
          orderBy,
          orderDirection,
          search,
          reportTypes,
          ...filterParams,
        },
      },
    );

    return data;
  }

  async getLocationReports(
    locationId: number | string,
    reportTypes: GetLocationReportTypes[],
    {
      limit = 10,
      offset = 0,
      orderBy,
      orderDirection,
      filterParams,
    }: PaginatedRequestParams,
  ) {
    const { data } = await axios.get<PaginatedData<ICombinedReport>>(
      `/organization/location/${locationId}/reports`,
      {
        params: {
          limit,
          offset,
          orderBy,
          orderDirection,
          reportTypes,
          ...filterParams,
        },
      },
    );

    return data;
  }

  async getLocationReportPDF(
    reportType: Omit<
      EReportTypeKeys,
      EReportTypeKeys.COVER_LETTERS | EReportTypeKeys.SUMMARY_OF_TESTING
    >,
    reportId: number | string,
  ) {
    const { data } = await axios.get(
      `/organization/location/${reportType}/${reportId}/download`,
      { responseType: 'blob' },
    );

    return data;
  }

  async uploadHistoricalDocument(
    reportType: Omit<
      EReportTypeKeys,
      EReportTypeKeys.COVER_LETTERS | EReportTypeKeys.SUMMARY_OF_TESTING
    >,
    locationId: number,
    body: FormData,
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/${reportType}/upload`,
      body,
    );

    return data;
  }

  async deleteLocationReport(
    reportType: Omit<
      EReportTypeKeys,
      EReportTypeKeys.COVER_LETTERS | EReportTypeKeys.SUMMARY_OF_TESTING
    >,
    reportId: number | string,
    locationId?: number | string,
  ) {
    const { data } = await axios.delete(
      `/organization/location${
        reportType ===
        EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING
          ? `/${locationId}`
          : ''
      }/${reportType}/${reportId}`,
    );

    return data;
  }

  async downloadCoverLetterAndSummaryOfTestingPDF(
    locationId: number,
    listItem: ICombinedReport,
    documentType: 'audiologist-cover-letter-pdf' | 'summary-of-testing-pdf',
  ) {
    if (!listItem.reportId || !locationId) return null;
    try {
      let fileName = '';
      if (!_.isEmpty(listItem.historicalDocument)) {
        fileName = documentType;
      } else if (documentType === 'audiologist-cover-letter-pdf') {
        fileName = `cover_letter_${listItem.name}`;
      } else {
        fileName = `summary_testing_${listItem.name}`;
      }
      const data =
        documentType === 'audiologist-cover-letter-pdf'
          ? await api.organization.getAudiologistCoverLetterPDF(
              locationId,
              listItem.reportId,
            )
          : await api.organization.getSummaryOfTestingPDF(
              locationId,
              listItem.reportId,
            );

      FileDownload(data, fileName);
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not download a PDF'),
        ),
        {
          variant: 'error',
        },
      );
    }
  }

  async getEquipmentSpecificationAndNoiseLevelsReportsForLocation(
    locationId: number | string,
    params: PaginatedRequestParams,
  ) {
    const { data } = await axios.get(
      `/organization/location/${locationId}/equipment-specifications-and-noise-levels-reports`,
      {
        params,
      },
    );

    return data as PaginatedData<IEquipmentSpecificationsAndNoiseLevelsReport>;
  }

  async getEquipmentSpecificationAndNoiseLevelsReportById(
    reportId: number | string,
  ) {
    const { data } = await axios.get(
      `/organization/location/equipment-specifications-and-noise-levels-reports/${reportId}`,
    );

    return data as IEquipmentSpecificationsAndNoiseLevelsReport;
  }

  async createEquipmentSpecificationAndNoiseLevelsReport(
    locationId: number,
    body: Omit<
      IEquipmentSpecificationsAndNoiseLevelsReport,
      'id' | 'created_at' | 'updated_at' | 'signedDocument'
    >,
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/equipment-specifications-and-noise-levels-reports/`,
      body,
    );

    return data as IEquipmentSpecificationsAndNoiseLevelsReport;
  }

  async updateEquipmentSpecificationAndNoiseLevelsReport(
    reportId: number,
    body: Omit<
      IEquipmentSpecificationsAndNoiseLevelsReport,
      'id' | 'created_at' | 'updated_at' | 'signedDocument'
    >,
  ) {
    const { data } = await axios.put(
      `/organization/location/equipment-specifications-and-noise-levels-reports/${reportId}`,
      body,
    );

    return data as IEquipmentSpecificationsAndNoiseLevelsReport;
  }

  async signEquipmentSpecificationAndNoiseLevelsReport(
    reportId: number,
    body: { signature: string; documentViewingDateTime: string },
  ) {
    const { data } = await axios.post(
      `/organization/location/equipment-specifications-and-noise-levels-reports/${reportId}/sign`,
      body,
    );

    return data as ISignedDocument;
  }

  async downloadSignedEquipmentSpecificationAndNoiseLevelsReport(
    reportId: number | string,
  ) {
    const { data } = await axios.get(
      `/organization/location/equipment-specifications-and-noise-levels-reports/${reportId}/download`,
      {
        responseType: 'blob',
      },
    );

    return data as any;
  }

  async createAudiologistCoverLetter(
    locationId: number,
    body: ICreateAudiologistCoverLetterBody,
  ) {
    const { data } = await axios.post(
      `/organization/location/${locationId}/audiologist-cover-letters`,
      body,
    );

    return data as ICoverLetterAndSummaryOfTesting;
  }

  async updateAudiologistCoverLetter(
    locationId: number,
    coverLetterId: number | string,
    body: ICreateAudiologistCoverLetterBody,
  ) {
    const { data } = await axios.put(
      `/organization/location/${locationId}/audiologist-cover-letters/${coverLetterId}`,
      body,
    );

    return data as ICoverLetterAndSummaryOfTesting;
  }

  async deleteAudiologistCoverLetter(
    locationId: number,
    coverLetterId: number | string,
  ) {
    const { data } = await axios.delete(
      `/organization/location/${locationId}/audiologist-cover-letters/${coverLetterId}`,
    );

    return data as ICoverLetterAndSummaryOfTesting;
  }

  async getAudiologistCoverLetterById(
    locationId: number,
    coverLetterId: number | string,
  ) {
    const { data } = await axios.get(
      `/organization/location/${locationId}/audiologist-cover-letters/${coverLetterId}`,
    );

    return data as ICoverLetterAndSummaryOfTesting;
  }

  async getAudiologistCoverLetterPDF(
    locationId: number,
    coverLetterId: number | string,
  ) {
    const { data } = await axios.get(
      `/organization/location/${locationId}/audiologist-cover-letters/${coverLetterId}/audiologist-cover-letter-pdf`,
      {
        responseType: 'blob',
      },
    );

    return data as any;
  }

  async getSummaryOfTestingPDF(
    locationId: number,
    coverLetterId: number | string,
  ) {
    const { data } = await axios.get(
      `/organization/location/${locationId}/audiologist-cover-letters/${coverLetterId}/summary-of-testing-pdf`,
      {
        responseType: 'blob',
      },
    );

    return data as any;
  }
}
