import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Grid } from '@mui/material';
import { Button } from 'components/common/button';
import { Add, Sync } from '@mui/icons-material';
import { api } from 'api';
import {
  IVisit,
  IVisitTime,
  VisitShift,
} from 'api/services/organization/types';
import BasicTable from 'components/table/BasicTable';
import { ConfirmationModal } from 'components/modal';
import { DateTime } from 'luxon';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import { useTableState } from 'hooks/useTableState';
import { UnscheduledParticipant, UserRoles } from 'api/services/auth/types';
import { useAuthSelector } from 'store/selectors/auth';
import _ from 'lodash';
import { cells } from './cells';
import { VisitSectionTitle } from '../../components';
import { VisitTimeForm } from './components/visit-time-form';
import { SendBookingInvitationsForm } from './components/SendBookingInvitationsForm';

interface VisitTimesProps {
  tableState: ReturnType<typeof useTableState<IVisitTime>>;
  visitLocationsShiftsTableState: ReturnType<typeof useTableState<VisitShift>>;
  unscheduledParticipantsTableState: ReturnType<
    typeof useTableState<UnscheduledParticipant>
  >;
  generateSchedule: () => void;
  generateLoading: boolean;
  sendBookingInvitations: (invitationType: 'phone' | 'email' | 'both') => void;
  timezone: string;
  visit?: IVisit;
}

export function VisitTimes({
  tableState,
  visitLocationsShiftsTableState,
  unscheduledParticipantsTableState,
  generateSchedule,
  generateLoading,
  sendBookingInvitations,
  timezone,
  visit,
}: VisitTimesProps) {
  const { locationId, visitId } = useParams();
  const { user } = useAuthSelector();
  const [formOpen, setFormOpen] = useState(false);
  const [sendBookingInvitationOpen, setSendBookingInvitationOpen] =
    useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [editedVisitTime, setEditedVisitTime] = useState<IVisitTime | null>(
    null,
  );

  const deleteVisitTime = async () => {
    if (!locationId || !visitId || !editedVisitTime) {
      return;
    }
    try {
      await api.organization.deleteVisitTime(
        locationId,
        visitId,
        editedVisitTime.id,
      );
      tableState.reloadData();
      visitLocationsShiftsTableState.reloadData();
      unscheduledParticipantsTableState.reloadData();
      showSnackbar('Visit Time successfully deleted', {
        variant: 'success',
      });
      setConfirmationOpen(false);
      setEditedVisitTime(null);
    } catch (e: any) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not delete Visit Time'),
        ),
        {
          variant: 'error',
        },
      );
    }
  };

  const handleQuestionnaireLinkCopy = useCallback(async () => {
    if (!visit) {
      return;
    }

    try {
      const siteUrl = new URL(window.location.href);
      await window.navigator.clipboard.writeText(
        `${siteUrl.origin}/verify/${visit.location.organization.name}`,
      );
      showSnackbar('The questionnaire link was copied to the clipboard!', {
        variant: 'success',
      });
    } catch (e) {
      console.log(e);
      showSnackbar(
        'Something went wrong with copying questionnaire link to the clipboard!',
        {
          variant: 'error',
        },
      );
    }
  }, [visit]);

  const actions = useMemo(
    () =>
      _.compact([
        {
          title: 'Edit',
          onClick: (visit: IVisitTime) => {
            setEditedVisitTime(visit);
            setFormOpen(true);
          },
        },
        (user?.role === UserRoles.SuperAdministrator ||
          user?.role === UserRoles.Administrator) && {
          title: 'Delete',
          onClick: (visit: IVisitTime) => {
            setEditedVisitTime(visit);
            setConfirmationOpen(true);
          },
        },
      ]),
    [user, visit],
  );

  return (
    <Box sx={{ borderRadius: '16px', background: '#F4F4F4', padding: 2 }}>
      <Grid container justifyContent="space-between" mb={3}>
        <VisitSectionTitle variant="h6">Scheduled Visit(s)</VisitSectionTitle>

        <Grid container item gap={1} xs={12} md={8} justifyContent="flex-end">
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              handleQuestionnaireLinkCopy();
            }}
          >
            Copy Questionnaire Link
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            endIcon={<Add fontSize="small" />}
            onClick={() => {
              setEditedVisitTime(null);
              setFormOpen(true);
            }}
          >
            Add visit time
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={generateSchedule}
            endIcon={
              generateLoading ? (
                <CircularProgress size="18px" sx={{ color: '#FFFFFF' }} />
              ) : (
                <Sync fontSize="small" />
              )
            }
            disabled={generateLoading}
          >
            Generate
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => setSendBookingInvitationOpen(true)}
          >
            Send Booking Invitation
          </Button>
        </Grid>
      </Grid>
      <BasicTable<IVisitTime>
        cells={cells(timezone)}
        tableState={tableState}
        actions={actions}
        isPagination={false}
        backgroundColor="#F4F4F4"
        contextButtonProps={{
          sx: {
            backgroundColor: 'rgba(202, 194, 190, 0.40);',
            width: '32px',
            height: '32px',
            borderRadius: '8px',
          },
        }}
      />

      {formOpen && locationId && visitId && (
        <VisitTimeForm
          handleClose={() => {
            setFormOpen(false);
            setEditedVisitTime(null);
          }}
          visitTime={editedVisitTime}
          locationId={locationId}
          visitId={visitId}
          timezone={timezone}
          handleSubmit={() => {
            setFormOpen(false);
            setEditedVisitTime(null);
            tableState.reloadData();
            visitLocationsShiftsTableState.reloadData();
            unscheduledParticipantsTableState.reloadData();
          }}
        />
      )}

      {editedVisitTime && (
        <ConfirmationModal
          open={confirmationOpen}
          handleClose={() => {
            setConfirmationOpen(false);
            setEditedVisitTime(null);
          }}
          handleSubmit={deleteVisitTime}
          submitButtonTitle="Delete"
          title={
            <>
              {`You are about to delete the Visit Time on ${DateTime.fromISO(
                editedVisitTime.startTime,
              ).toFormat('ccc, LLL dd, y')}`}
              <br />
              Are you sure?
            </>
          }
        />
      )}

      {sendBookingInvitationOpen && (
        <SendBookingInvitationsForm
          opened={sendBookingInvitationOpen}
          handleClose={() => {
            setSendBookingInvitationOpen(false);
          }}
          handleSubmit={sendBookingInvitations}
        />
      )}
    </Box>
  );
}
