import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { IQuestionnaireResults } from 'api/services/questionnaire/types';
import { useAuthSelector } from 'store/selectors/auth';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { ErrorScreen } from 'components/common/error-screen';
import { useDispatch } from 'react-redux';
import { logoutUserRequest } from 'store/reducers/auth/actions';
import { Participant } from 'api/services/auth/types';
import {
  getErrorMessage,
  getErrorMessageComponent,
  showSnackbar,
} from '../../utils';
import { api } from '../../api';
import { ParticipantHearingReport } from './components';
import { useTableState } from '../../hooks/useTableState';
import {
  EHearingTestResultTypes,
  HearingTestResult,
} from '../../api/services/hearing-test-results/types';

export function ParticipantOwnHearingReportPage() {
  const { user: participant } = useAuthSelector();
  const dispatch = useDispatch();
  const [questionnaireResults, setQuestionnaireResults] =
    useState<IQuestionnaireResults | null>(null);
  const [questionnaireResultsLoading, setQuestionnaireResultsLoading] =
    useState(true);
  const [error, setError] = useState<boolean | string>(false);

  const [selectedTestResult, setSelectedTestResult] =
    useState<HearingTestResult | null>(null);

  useEffect(() => {
    const asyncRequest = async () => {
      if (!selectedTestResult?.id || !participant?.id) return;
      try {
        setQuestionnaireResultsLoading(true);
        const questionnaireResultData =
          await api.questionnaire.getQuestionnaireResults(
            participant.id,
            selectedTestResult.id,
          );
        setQuestionnaireResults(questionnaireResultData);
      } catch (e) {
        if ((e as any).request?.status !== 404) {
          showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
            variant: 'error',
          });
        }
      } finally {
        setQuestionnaireResultsLoading(false);
      }
    };
    asyncRequest();
  }, [selectedTestResult]);

  const [baselineTestResult, setBaselineTestResult] =
    useState<HearingTestResult | null>(null);

  const fetchParticipantHearingTestResults = useCallback(
    async (limit: number, offset: number) => {
      try {
        if (!participant?.id) return null;
        const data = await api.participant.getHearingTestResultsByParticipantId(
          participant.id,
          { limit, offset },
        );
        setSelectedTestResult(_.last(data.items) || null);
        return data;
      } catch (e) {
        setError('Currently, you have no results to review.');
        return EMPTY_PAGINATED_DATA;
      }
    },
    [participant],
  );

  const tableState = useTableState<HearingTestResult>({
    fetchDataFunction: fetchParticipantHearingTestResults,
  });

  useEffect(() => {
    if (!tableState.data || !selectedTestResult) return;
    const baselineTestResults = tableState.data
      .filter((item) => item.type === EHearingTestResultTypes.Baseline)
      .sort(
        (a, b) =>
          DateTime.fromISO(b.dateTime).toMillis() -
          DateTime.fromISO(a.dateTime).toMillis(),
      );
    setBaselineTestResult(
      baselineTestResults.find(
        (item) =>
          DateTime.fromISO(item.dateTime).toMillis() <=
          DateTime.fromISO(selectedTestResult.dateTime).toMillis(),
      ) || null,
    );
  }, [tableState.data, selectedTestResult]);

  const onUpdate = useCallback(async () => {
    if (!selectedTestResult) return;
    try {
      await tableState.refreshData();
    } catch (e) {
      showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
        variant: 'error',
      });
    }
  }, [tableState, selectedTestResult]);

  return (
    <Grid container direction="column">
      {error ? (
        <ErrorScreen
          errorMessage={error}
          action={() => {
            dispatch(logoutUserRequest());
          }}
        />
      ) : (
        <ParticipantHearingReport
          participant={
            {
              ...(participant as unknown as Participant),
              lastTestDate: selectedTestResult?.dateTime || '',
            } || null
          }
          hearingTestResult={selectedTestResult}
          baseLineHearingTestResult={
            selectedTestResult?.type !== EHearingTestResultTypes.Baseline
              ? baselineTestResult
              : null
          }
          questionnaireResults={questionnaireResults}
          questionnaireResultsLoading={questionnaireResultsLoading}
          onUpdate={onUpdate}
          loading={false}
        />
      )}
    </Grid>
  );
}
